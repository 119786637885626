<template>
    <div v-if="listData.length">
        <div style="display: flex;flex-wrap: wrap;align-items: center;justify-content: center;margin: 20px 0;">
            <div class="title">Limited time flash sale</div>
            <el-button style="text-transform: uppercase;" @click="$router.push('/promotion/seckill')">view all</el-button>
        </div>
        <el-carousel :autoplay="false" indicator-position="none" :loop="false" arrow="always" style="height: 320px;">
            <el-carousel-item v-for="(v, i) in   listData  " :key="i">
                <div class="item" v-for="(item, index) in   v  " :key="index"
                    @click="$router.pushToTab({ path: `/promotion/seckill-${item.id}` })">

                    <div class="img">
                        <el-image style="height: 240px;" :src="item.goods_image" fit="fit"></el-image>
                    </div>
                    <div class="text">
                        <p>{{ item.goods_name }}</p>
                        <span>PH{{ item.seckill_price }}</span>
                        <span>PH{{ item.price }}</span>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
import {
    goodsPage,
    timeList
} from "@/api/seckill"
export default {
    name: 'FlashSale',
    data() {
        return {
            listData: [],
            seckillTimeMachine: '',
        }
    },
    created() {
        this.getTimeList();
    },
    watch: {
        listData(newValue) {
            console.log(newValue, this.seckillTimeMachine);
        }
    },
    methods: {
        /**
         * 限时秒杀
         */
        getTimeList() {
            timeList()
                .then(res => {
                    if (res.code == 0 && res.data) {
                        let time = new Date(res.timestamp * 1000)
                        let currentTimes = time.getHours() * 60 * 60 + time.getMinutes() * 60 + time.getSeconds()
                        res.data.list.forEach((v, k) => {
                            if (v.seckill_start_time <= currentTimes && currentTimes < v.seckill_end_time) {
                                let seckillId = v.id
                                this.getGoodsList(seckillId)

                                let endTime = parseInt(time.getTime() / 1000) + (v.seckill_end_time - currentTimes)
                                this.seckillTimeMachine = {
                                    currentTime: res.timestamp,
                                    startTime: res.timestamp,
                                    endTime: endTime
                                }
                            }
                        })
                    }
                })
                .catch(err => {
                    this.$message.error(err.message)
                })
        },
        /**
         * 秒杀商品
         */
        getGoodsList(id) {
            goodsPage({
                page_size: 0,
                seckill_id: id,
                site_id: 4
            })
                .then(res => {
                    if (res.code == 0 && res.data.list) {
                        this.listData = this.divisionArr(res.data.list, 5)
                    }
                })
                .catch(err => { })
        },
        divisionArr(arr, num) {
            let newArr = [...arr] // 因为splice会改变原数组，要深拷贝一下
            let list = []
            for (let i = 0; i < newArr.length;) {
                list.push(newArr.splice(i, num))
            }
            return list
        },
    },
}
</script>
<style scoped lang="scss">
.title {
    // font-family: 'Courier New', Courier, monospace;
    font-family: Tiemann, serif;
    font-size: 30px;
    // font-size: 1.88125em;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .025em;
    text-align: center;
    margin: 10px 0;
    width: 100%;
}

.bg-purple-dark {
    background: #99a9bf;
    border: 1px solid red;

}


.el-carousel__item {
    display: flex;
    height: 320px;


    .item {
        width: calc((100% - 10px) / 5);
        box-sizing: border-box;
        border: 1px solid #fff;
        padding: 10px 0;
        text-align: center;

        .img {
            height: 240px;
            overflow: hidden;
        }

        .text {
            margin: 5px 0;

            p {
                font-size: 12px;
            }

            span {
                color: #FF547B;

                &:nth-of-type(2) {
                    margin-left: 10px;
                    color: #7d7c7c;
                    text-decoration: line-through;
                }
            }


        }
    }

    .item:hover {
        box-sizing: border-box;
        border: 1px solid #FF547B;
    }
}
</style>